import { DocScripts } from "../components/widgets/codeBlock";

export const catalogoScripts = {
    regimen: {
        requests: [],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
[
    {
        "clave": "601",
        "descripcion": "General de Ley Personas Morales"
    },
    {
        "clave": "603",
        "descripcion": "Personas Morales con Fines no Lucrativos"
    },
    {
        "clave": "605",
        "descripcion": "Sueldos y Salarios e Ingresos Asimilados a Salarios"
    },
    {
        "clave": "606",
        "descripcion": "Arrendamiento"
    },
    ...
]`
        }
    } as DocScripts,
    usocfdi: {
        requests: [],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
[
    {
        "clave": "G01",
        "persFisica": true,
        "persMoral": true,
        "descripcion": "Adquisición de mercancías"
    },
    {
        "clave": "I01",
        "persFisica": true,
        "persMoral": true,
        "descripcion": "Construcciones"
    },
    {
        "clave": "I02",
        "persFisica": true,
        "persMoral": true,
        "descripcion": "Mobiliario y equipo de oficina por inversiones"
    },
    {
        "clave": "G03",
        "persFisica": true,
        "persMoral": true,
        "descripcion": "Gastos en General"
    },
    ...
]`
        }
    } as DocScripts,
    paytypes: {
        requests: [],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
[
    {
        "key": "01",
        "label": "Efectivo"
    },
    {
        "key": "02",
        "label": "Cheque"
    },
    {
        "key": "03",
        "label": "Transferencia"
    },
    ...
]`
        }
    } as DocScripts,
    productoservicio: {
        requests: [{
            language:'JSON',
            subtitle: 'Cuerpo del request',
            subsubtitle: '(application / json)',
            code: `
{
    "compare": "cafetería",
}`
        }],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
[
    {
        "clave": "95121503",
        "descripcion": "Cafetería",
        "trasladoIVA": false,
        "trasladoIEPS": false,
        "complemento": "",
        "palabrasSimilares": "",
        "match": 10
    },
    {
        "clave": "90101700",
        "descripcion": "Servicios de cafetería",
        "trasladoIVA": false,
        "trasladoIEPS": false,
        "complemento": "",
        "palabrasSimilares": "",
        "match": 3.3333333333333335
    },
    {
        "clave" : "10101902",
        "descripcion" : "Escarabajos",
        "trasladoIVA" : false,
        "trasladoIEPS" : false,
        "complemento" : "",
        "palabrasSimilares" : "Mariquitas,Sanjuaneros",
        "match": -1
    }    
    ...
]`
        }
    } as DocScripts,
    unidades: {
        requests: [{
            language:'JSON',
            subtitle: 'Cuerpo del request',
            subsubtitle: '(application / json)',
            code: `
{
    "compare": "Kilogramo",
}`
        }],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
[
    {
        "clave": "KGM",
        "nombre": "Kilogramo",
        "simbolo": "kg",
        "match": 14
    },
    {
        "clave": "M94",
        "nombre": "Kilogramo metro",
        "simbolo": "kg·m",
        "match": 9
    },
    {
        "clave": "53",
        "nombre": "Kilogramo teórico",
        "simbolo": "",
        "match": 9
    },
    {
        "clave": "QK",
        "nombre": "Cuarto de kilogramo",
        "simbolo": "",
        "match": 7.333333333333334
    },
    ...
]`
        }
    } as DocScripts,
    impuestos: {
        requests: [],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
[
    {
        "tipo": "Fijo",
        "min": null,
        "max": 0,
        "impuesto": "IVA",
        "factor": "Tasa",
        "traslado": true,
        "retencion": false,
    },
    {
        "tipo": "Fijo",
        "min": null,
        "max": 0.3,
        "impuesto": "IEPS",
        "factor": "Tasa",
        "traslado": true,
        "retencion": true,
    },
    {
        "tipo": "Rango",
        "min": 0,
        "max": 0.16,
        "impuesto": "IVA",
        "factor": "Tasa",
        "traslado": false,
        "retencion": true,
    },
    {
        "tipo": "Fijo",
        "min": null,
        "max": 0.265,
        "impuesto": "IEPS",
        "factor": "Tasa",
        "traslado": true,
        "retencion": true,
    },
    {
        "tipo": "Fijo",
        "min": null,
        "max": 0.16,
        "impuesto": "IVA",
        "factor": "Tasa",
        "traslado": true,
        "retencion": false,
    }, // El IVA tradicional de 16% trasladado
    ...
]`
        }
    } as DocScripts,
    estados: {
        requests: [],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
[
    {
        "clave": "01",
        "nombre": "AGUASCALIENTES"
    },
    {
        "clave": "02",
        "nombre": "BAJA CALIFORNIA NORTE"
    },
    {
        "clave": "03",
        "nombre": "BAJA CALIFORNIA SUR"
    },
    {
        "clave": "04",
        "nombre": "CAMPECHE"
    },
    ...
]`
        }
    } as DocScripts,
    paises: {
        requests: [],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
[
    {
        "clave": "AFG",
        "pais": "AFGANISTAN (EMIRATO ISLAMICO DE)"
    },
    {
        "clave": "ALB",
        "pais": "ALBANIA (REPUBLICA DE)"
    },
    {
        "clave": "DEU",
        "pais": "ALEMANIA (REPUBLICA FEDERAL DE)"
    },
    ...
]`
        }
    } as DocScripts,
    relacionFacturas: {
        requests: [],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
[
    {
        "clave": "01",
        "descripcion": "Nota de crédito de los documentos relacionados"
    },
    {
        "clave": "02",
        "descripcion": "Nota de débito de los documentos relacionados"
    },
    {
        "clave": "03",
        "descripcion": "Devolución de mercancía sobre facturas o traslados previos"
    },
    ...
]`
        }
    } as DocScripts,
    inmuebles: {
        requests: [],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
[
    {
        "clave": "01",
        "descripcion": "Terreno"
    },
    {
        "clave": "02",
        "descripcion": "Terreno uso comercial"
    },
    {
        "clave": "03",
        "descripcion": "Construcción habitacional"
    },
    ...
]`
        }
    } as DocScripts,
    blacklist: {
        requests: [{
            language:'JSON',
            subtitle: 'Cuerpo del request',
            subsubtitle: '(application / json)',
            code: `
{
    rfc: 'BBGA790512KL9'
}`
        }],
        response: {
            language: 'JSON',
            subtitle: 'Respuesta correcta',
            code: `
{
    "success": true, // true | false
    "message": "El RFC no se encuentra en la lista del código 69B"
}`
        }
    } as DocScripts,
}